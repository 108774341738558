//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Hero from '@/components/pages/home/Hero.vue'
import LatestNews from '@/components/pages/home/LatestNews.vue'
import LatestStatistics from '@/components/pages/home/LatestStatistics.vue'
import LatestBusinessCompany from '@/components/pages/home/LatestBusinessCompany.vue'
import StockPrices from '@/components/pages/home/StockPrices.vue'
import InteractiveMap from '@/components/pages/home/InteractiveMap.vue'
import SearchContent from '@/components/shared/SearchContent.vue'
import LatestAdvertisement from '@/components/pages/home/LatestAdvertisement.vue'

export default {
    name: 'Home',
    components: {
        Hero,
        LatestNews,
        LatestStatistics,
        LatestBusinessCompany,
        StockPrices,
        InteractiveMap,
        SearchContent,
        LatestAdvertisement
    },
    data() {
        return {
            info: null
        }
    },
    mounted() {
        //let self = this;
        //this.axios
        //    .get(process.env.VUE_APP_APIEndPoint + 'Banner/PublicGetAll')
        //    .then(response => (this.info = response));

        setTimeout(function () {
            //businessDepartmentsSliderFunc();
            //homeNewsSlider();
        }, 100)
    }
};
