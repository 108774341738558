//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "LatestBusinessCompany",
    data() {
        return {
            LatestBusinessCompanyList: [],
            isLoading: true
        };
    },
    methods: {
       
    },

    //mounted() {
    //    let self = this;
    //    self.axios
    //        .get(process.env.VUE_APP_APIEndPoint + "CompanyManagement/GetLatest")
    //        .then((response) => {
    //            self.LatestBusinessCompanyList = response.data.Data;
    //        }).finally(
    //            () => self.isLoading = false
    //        );
    //},
    //updated() {
    //    businessCategoriesSliderInit();
    //},

    mounted() {
        this.isLoading = false
        setTimeout(function () {
            businessCategoriesSliderInit();
        }, 100)
    },
    
};
