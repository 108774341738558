//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "LatestStatistics",
    data() {
        return {
            LateststatisticsList: [],
            isLoading: true,
        };
    },
    methods: {
       
    },

    mounted() {
        let self = this;
        debugger;
        self.axios
            .get(process.env.VUE_APP_APIEndPoint + "Statistics/GetLatest")
            .then((response) => {
                self.LateststatisticsList = response.data.Data;
            })
            .finally(() => self.isLoading = false);
    },
};
